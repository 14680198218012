<template>
  <div>
    <v-dialog v-model="olahDataDialog" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h6 font-weight-bold">
          {{ olahDataTitle }}
        </v-card-title>

        <v-divider class="mx-5"></v-divider>

        <v-card-text class="pb-0 mt-3">
          <v-form ref="form" v-model="valid">
            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Kode Customer
            </v-card-title>
            <v-text-field
              v-model="olahData.kode"
              outlined
              dense
              :rules="[rules.required]"
              autocomplete="off"
            ></v-text-field>

            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Nama Customer
            </v-card-title>
            <v-text-field
              v-model="olahData.nama"
              outlined
              dense
              :rules="[rules.required]"
              autocomplete="off"
            ></v-text-field>

            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Area Customer
            </v-card-title>
            <v-text-field
              v-model="olahData.area"
              outlined
              dense
              :rules="[rules.required]"
              autocomplete="off"
            ></v-text-field>

            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              No. Telepon
            </v-card-title>
            <v-text-field
              v-model="olahData.telp"
              outlined
              dense
              :rules="[rules.required]"
              autocomplete="off"
            ></v-text-field>

            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Email
            </v-card-title>
            <v-text-field
              v-model="olahData.email"
              outlined
              dense
              :rules="[rules.email]"
              autocomplete="off"
            ></v-text-field>

            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Alamat
            </v-card-title>
            <v-textarea
              v-model="olahData.alamat"
              outlined
              dense
              :rules="[rules.required]"
              autocomplete="off"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-card-actions class="pt-0 pb-5">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="#61B15A"
            style="text-transform: unset !important"
            @click="closeOlahData"
          >
            Batal
          </v-btn>
          <v-btn
            color="#61B15A"
            class="white--text"
            style="text-transform: unset !important"
            @click="checkType"
            :disabled="!valid"
            :loading="loading"
          >
            {{ olahDataBtn }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="popDialog" width="500">
      <v-card color="red" rounded>
        <v-card-title class="text-h6 white--text">
          Data Gagal Ditambahkan !!
        </v-card-title>
        <v-card-title class="text-caption white--text">
          Periksa kembali inputan anda. Username tidak boleh sama dengan user yang lain.
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-green darken-1"
            class="white--text"
            @click="closePopDialog"
            >Tutup</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    olahDataDialog: Boolean,
    olahDataTitle: String,
    olahDataBtn: String,
    typeAction: String,
    olahData: Object,
  },
  data: () => ({
    valid: false,
    loading: false,
    rules: {
      required: (value) => !!value || "Tidak boleh kosong !!",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          
          // if(value.length !== 0)
        return !value || pattern.test(value) || "e-mail tidak valid !!";
    
      },
    },
    popDialog: false,
  }),
  methods: {
    checkType() {
      this.loading = true;
     console.log(this.olahData.email);
      if (!this.olahData.email){
        this.$set(this.olahData,"email","");
         console.log(this.olahData);
      }
      
      this.olahData.kode = this.olahData.kode.toUpperCase();
      this.olahData.area = this.olahData.area.replace(/\b\w/g, (c) =>
        c.toUpperCase()
      );
      this.olahData.nama = this.olahData.nama.replace(/\b\w/g, (c) =>
        c.toUpperCase()
      );
      this.olahData.alamat = this.olahData.alamat.replace(/\b\w/g, (c) =>
        c.toUpperCase()
      );
      if (this.typeAction === "edit") {
        this.edit();
      } else if (this.typeAction === "new") {
        this.new();
      }
    },
    new() {
        const data = JSON.stringify(this.olahData);
        axios
        .post("v1/admin/customer", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.loading = false;
          this.$emit("reloadData");
          this.closeOlahData();
        })
        .catch((e) => {
          console.log(e);
          this.popDialog = true;
        });
    },
    edit() {
        const data = JSON.stringify(this.olahData);
        axios
        .put("v1/admin/customer", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 201) {
            this.loading = false;
            this.$emit("reloadData");
            this.closeOlahData();
          }
        })
        .catch(() => {
          this.popDialog = true;
        });
    },
    closeOlahData() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$emit("closeOlahDataDialog");
    },
    closePopDialog() {
        this.loading = false;
        this.popDialog = false;
    }
  },
};
</script>