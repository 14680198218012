<template>
  <div>
    <v-container fluid class="px-0">
      <v-card outlined elevation="3">
        <v-card-text>
          <v-data-table
            class="black--text"
            :headers="headers"
            :search="search"
            :items="dataCustomer"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            :page.sync="page"
            :loading="loading"
            hide-default-footer
            dense
          >
            <template v-slot:top>
              <v-toolbar flat dense class="mb-3">
                <v-row>
                  <v-col md="8">
                    <v-text-field
                      outlined
                      dense
                      v-model="search"
                      append-icon="mdi-magnify"
                      autocomple="off"
                      placeholder="Search..."
                      hide-details
                      @keydown.enter="changeItemPerPage"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="modify" md="4" align="right">
                    <v-btn
                      color="#61B15A"
                      class="text-subtitle-2 white--text"
                      style="text-transform: unset !important"
                      @click="checkType(0)"
                    >
                      <v-icon left>mdi-plus-circle</v-icon>
                      Tambah Customer
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>

            <template v-slot:item.is_activated="{ item }">
              <v-chip
                small
                class="font-weight-bold error--text"
                v-if="item.is_activated === 0"
              >
                Disabled
              </v-chip>
              <v-chip small class="font-weight-bold primary--text" v-else>
                Active
              </v-chip>
            </template>

            <template v-if="modify" v-slot:item.actions="{ item }">
              <v-btn
                color="#FFA900"
                small
                class="text-caption white--text mr-2"
                style="text-transform: unset !important"
                @click.prevent="checkType(item.id)"
              >
                Edit
              </v-btn>
              <v-btn
                color="#EB4646"
                small
                class="text-caption white--text mr-2"
                style="text-transform: unset !important"
                @click.prevent="hapus(item)"
              >
                <span v-if="item.is_activated === 0">Active</span>
                <span v-else>Disabled</span>
              </v-btn>
            </template>
          </v-data-table>
          <div class="pt-2">
            <v-row>
               <v-col cols="2">
                <v-select
                  v-model="itemsPerPage"
                  :items="showPerPage"
                  outlined
                  dense
                  label="items per page"
                  v-on:change="changeItemPerPage"
                >
                </v-select>
              </v-col>
              <v-col align="center">
            <v-pagination
              v-model="page"
              :length="pageCount"
              @input="next"
            ></v-pagination>
            </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-container>

    <olah-data
      :olahDataDialog="olahDataDialog"
      :olahDataTitle="olahDataTitle"
      :olahDataBtn="olahDataBtn"
      :typeAction="typeAction"
      :olahData="olahData"
      @closeOlahDataDialog="closeOlahDataDialog"
      @reloadData="reloadData"
    />

    <v-dialog v-model="progress" width="500">
      <v-card>
        <v-card-text class="pt-5">
          <v-progress-linear indeterminate color="#FFA900"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="popDialog" width="450">
      <v-card color="white" rounded>
        <v-card-title class="justify-center text-h6 black--text">
          {{ popTitle }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-green darken-1"
            class="white--text"
            @click="closePopDialog"
            >Tutup</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h6 font-weight-bold black--text">
          Ubah status Customer
        </v-card-title>
        <v-card-subtitle class="mt-1 text-subtitle-2 black--text">
          Status Customer akan dirubah. Yakin melanjutkan proses ini?
        </v-card-subtitle>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="#61B15A"
            style="text-transform: unset !important"
            @click="closeDelete"
          >
            Batal
          </v-btn>
          <v-btn
            color="#EB4646"
            class="white--text"
            style="text-transform: unset !important"
            @click="deleteItemConfirm"
          >
            Proses
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import olahData from "./olahData.vue";

export default {
  components: { olahData },
  props: { modify: Boolean },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      {
        text: "Kode Customer",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "kode",
      },
      {
        text: "Area Customer",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "area",
      },
      {
        text: "Nama Customer",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "nama",
      },
      {
        text: "Alamat Customer",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "alamat",
      },
      {
        text: "No. Telepon",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "telp",
      },
      {
        text: "Email",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "email",
      },
      {
        text: "Status",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "is_activated",
      },
      {
        text: "Action",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "actions",
      },
    ],
    dataCustomer: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    dataLength: "",
    typeAction: "",
    olahDataTitle: "",
    olahDataBtn: "",
    olahDataDialog: false,
    olahData: {},
    progress: false,
    popDialog: false,
    popTitle: "",
    deleteData: {},
    status: "",
    dialogDelete: false,
    showPerPage: [10,100, 250, 500, 1000],
  }),

  methods: {
    ...mapActions({
      refreshAction: "auth/refresh",
    }),

    getData() {
      this.loading = true;
      axios
        .get(
          "v1/admin/customer/list?limit=" +
            this.itemsPerPage +
            "&page=" +
            this.page +
            "&srctxt=" +
            this.search
        )
        .then((response) => {
          this.loading = false;
          this.dataCustomer = response.data.data;
          this.getDataLength();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.refreshAction(localStorage.getItem("refresh"));
              this.getData();
            }
            console.clear();
          }
        });
    },
    getDataLength() {
      axios.get("v1/admin/customer/list?limit=9999999").then((response) => {
        this.dataLength = response.data.data.length;
        var count = response.data.data.length / this.itemsPerPage;
        if (count < 1) {
          this.pageCount = 0;
        } else {
          this.pageCount = Math.ceil(
            response.data.data.length / this.itemsPerPage
          );
        }
      });
    },
    getDataById(id) {
      axios.get("v1/admin/customer?id=" + id).then((response) => {
        this.olahData = response.data.data;
        this.typeAction = "edit";
        this.olahDataTitle = "Edit Customer";
        this.olahDataBtn = "Edit & Simpan";
        this.progress = false;
        this.olahDataDialog = true;
      });
    },
    next(page) {
      this.page = page;
      this.getData();
    },
    changeItemPerPage() {
      this.getData();
    },
    checkType(value) {
      if (value === 0) {
        this.typeAction = "new";
        this.olahDataTitle = "Tambah Customer";
        this.olahDataBtn = "Tambahkan";
        this.olahDataDialog = true;
      } else {
        this.progress = true;
        this.getDataById(value);
      }
    },
    hapus(value) {
      this.deleteData = value;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.loading = true;
      this.dataCustomer = [];
      this.typeAction = "edit";
      const data = this.deleteData;
      if (this.deleteData.is_activated === 0) {
        data.is_activated = 1;
      } else {
        data.is_activated = 0;
      }
      const dataSend = JSON.stringify(data);
      axios
        .put("v1/admin/customer", dataSend, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 201) {
            this.deleteData = {};
            this.reloadData();
            this.closeDelete();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.loading = false;
            console(error.response);
          }
        });
    },
    closeDelete() {
      this.dataCustomer = [];
      this.deleteData = {};
      this.getData();
      this.dialogDelete = false;
    },
    closeOlahDataDialog() {
      this.olahData = {};
      this.dataCustomer = [];
      this.getData();
      this.olahDataDialog = false;
    },
    closePopDialog() {
      this.popTitle = "";
      this.popDialog = false;
    },
    reloadData() {
      if (this.typeAction === "edit") {
        this.popTitle = "Data Customer berhasil diperbaharui";
        this.popDialog = true;
      } else if (this.typeAction === "new") {
        this.popTitle = "Data Customer berhasil ditambahkan";
        this.popDialog = true;
      }
    },
  },

  created() {
    this.getData();
  },
};
</script>